@import '../../../../style/variables';

.verify-form-component {
  display: flex;
  flex-direction: column;

  .verify-fields-container {
    margin: 0;
  }

  .resend-cta-container {
    margin: 0rem auto 3rem;
  }
  
  .resend-cta {
    font-family: $font400;
    text-decoration: underline;
    text-align:center;
    border:none;
    background-color: $color-white;
    cursor: pointer;
  }

  .verify-button-container {
    display: flex;
    margin-bottom: 3rem;

    .verify-button {
      display: flex;
      width:100%;
      border:none;
      background-color: $color-primary;
      padding: 16px 20px;
      margin-bottom: 1.9rem;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 1.5rem;
      font-family: $font700;
      color: $color-white;
      border-radius: 1000px;
    }
  }
}
