@import '../../style/variables';

.back-button-component {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: $color-gray-150;
  width: 76px;
  height: 56px;
  border-radius: 1000px;
  .back-button-icon {
    width: 16px;
    height: 12px;
  }
  .sr-only {
    position: absolute;
    top:-10000px;
    left:-10000px;
  }
}
