@import '../../../../style/variables';
@import '../../../../style/breakpoints';

.flex-center {
  display:flex;
  justify-content: center;
  align-items: center;
}
.column {
  display:flex;
  flex-direction: column;
}
.row {
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
}
.space-between {
  align-items: center;
  justify-content: space-between;
}
.flex {
  flex: 1;
}
.flex-grow {
  flex: 1.5;
}
.mb {
  margin-bottom: 2rem;
}
.primary200 {
  background-color: $color-primary-200;
}
.gray200 {
  background-color: $color-gray-200;
}
.separator {
  margin: 2rem auto;
  height:1px;
}
.cta-container {
  margin-top:2rem;
  .cta-button {
    text-decoration: none;
    display: flex;
    width: auto;
    max-width: 90%;
    margin: 0 auto;
    border:none;
    white-space: nowrap;
    background-color: $color-primary;
    padding: 16px 20px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1.5rem;
    font-family: $font700;
    color: $color-white;
    border-radius: 1000px;
  }
}

.success-card-component {
  width:100%;
  
  .blue-bg {
    padding-top: 6rem;
    background: linear-gradient(rgba(246, 247, 254, 100%) 0%,rgba(246, 247, 254,30%) 63%,rgba(255, 255, 255,100%) 63%, rgba(255, 255, 255,100%) 100%);

    .title-container {
      max-width:500px;
      margin: 0 auto;
      .title {
        color: $color-black;
        font-family: $font700;
        font-size: 4.4rem;
        line-height: 1.4;
        text-align: center;
        margin-bottom: 1rem;
        background: -webkit-linear-gradient(#5650F0, #BB45F3);
        background-clip: text;
        -webkit-background-clip: text; 
        -webkit-text-fill-color: transparent;
      }

      .subtitle {
        color: $color-black;
        font-family: $font600;
        font-size: 2.1rem;
        line-height: 1.4;
        font-weight: 600;
        text-align: center;
      }

      .notice {
        color: $color-black;
        font-family: $font400;
        font-size: 1.4rem;
        line-height:1.4;
        font-weight:400;
        text-align: center;
        margin-bottom: 3rem;
      }
      
    }
  }
  .splash-container {
    
    width: 100%;

    .cards-image {
      display: block;
      width:100%;
      max-width: 656px;
      object-fit: cover;
    }
  }
  .learn-more-container {
    margin: 5rem auto 8rem auto;
    .title-container {
      max-width: 940px;
      .title {
        font-family: $font700;
        font-weight: 700;
        font-size:4rem;
        line-height: 1.4;
        text-align:center;
        margin-bottom: 1rem;
      }
      .subtitle {
        font-family: $font400;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 1.4;
        text-align:center;
        margin-bottom: 1rem;
      }
    }
  }
  .get-started-container {
    
    max-height:668px;
    width:100%;
    height:100%;
    background-image: url("../../../../assets/images/colored_bg.jpg");
    background-size:cover;
    padding: 8rem 2rem;

    .callout-container {
      
      background-color: $color-white;
      max-width:1240px;
      width:100%;
      border-radius: 5rem;
      overflow: hidden;
      .cta-container {
        display:none;
      }
      @media all and (max-width: $tablet){ 
        text-align: center;
        margin: auto 2rem;
        .cta-container {
          display:block;
        }
       }
      > div {
        flex: 1;
      }
      .title-container {
        padding: 4rem 0.2rem;
        .title-wrapper {
          max-width: 406px;
          padding: 1rem;
        }
        .title {
          font-family: $font700;
          font-weight: 700;
          font-size:4rem;
          line-height: 1.2;
          margin-bottom:1rem;
        }
        .subtitle {
          font-family: $font400;
          font-weight: 400;
          font-size: 1.5rem;
          line-height: 1.4;
        }
        @media all and (max-width: $tablet){ 
          text-align: center;
         }
      }
    }
    .qr-cta-container {
      
      width:100%;
      height:100%;
      min-height:428px;
      background-image: url("../../../../assets/images/ellipse.png");
      background-size: cover;
      background-repeat: no-repeat;
      @media all and (max-width: $tablet){ 
       display: none; 
      }
    }
    .cta-qr-code-wrapper {
      position: relative;
      background-color:$color-white;
      border-radius: 1rem;
    }
    .cta-qr-code-image {
      display: block;
      object-fit: contain;
      width: 100%;
      height: 240px;
      padding:20px;
    }
    .black-cta-button {
      position: absolute;
      bottom: -28px;
      z-index:99;
      display:block;
      background-color: $color-black;
      padding:1rem;
      text-align:center;
      font-family:$font400;
      color:$color-white;
      border-radius:0.5rem;
      text-decoration: none;
    }
  }
  footer {
    
    max-width:1240px;
    width:100%;
    margin: 0 auto;
    padding: 4rem 1rem;
    @media all and (max-width: $tablet){ 
      padding: 4rem 2rem;
    }

    h2 {
      font-family: $font700;
      font-size: 2.66rem;
      min-height: 54px;
    }

    h3 {
      font-family: $font700;
      font-size: 1.2rem;
      text-transform: uppercase;
      color: $color-grey-600;
      margin-top: 1.2rem;
      min-height: 40px;
    }

    a.footer-nav {
      font-family: $font400;
      font-size: 1.34rem;
      line-height: 1.4;
      text-decoration: none;
      cursor: pointer;
      margin-bottom: 1.2rem;
      color: $color-black;
    }
    
    .svg-icon {
      margin-right:1rem;
    }
    .disclaimer {
      font-family: $font400;
      font-size: 1.34rem; 
      line-height: 1.5;
    }
    .disclosure {
      font-family: $font400;
      font-size: 1.1rem; 
      color: $color-gray-700;
    }
    .padding {
      padding:4rem 0;
    }
    .social-logo {
      width: 24px;
      height: 24px;
    }
    .x-logo {
      padding-top:2px;
    }
    @media all and (max-width: $tablet){ 
      h2, h3 {
        margin-top: 4rem;
        margin-bottom:1rem;
      }
      > .row {
        flex-direction: column;
      }
    }
  }
}
