@import '../../../../style/variables';

.not-found-card-component {
  display: flex;
  flex-direction: column;
  width:100%;

  h1 {
    font-size: 3rem;
    font-family: $font700;
    font-weight:700;
    line-height:1.4;
    margin-bottom: 4rem;
  }
  .splash-container {  
    width: 100%;
    max-width: 450px;
    img {
      display: block;
      width:100%;
      object-fit: cover;
    }
  }
  .not-found-cta-wrapper {
    background-color: $color-black;
    border-radius: 1000px;
    padding: 1rem 2rem;

    .not-found-cta {
      display: block;
      
      font-family: $font400;
      font-weight: 400;
      line-height: 1.4;
      font-size: 1.4rem;
      color: $color-white;
      text-decoration: none;
    }
  }
}
