$color-primary: #5650F0;
$color-black: #0F1419;
$color-white: #FFFFFF;
$color-gray-50: #F5F5F5;
$color-gray-100: #FAFAFA;
$color-gray-150: #F2F5F8;
$color-gray-200: #E8F0F3;
$color-gray-300: #DAE0E4;
$color-gray-400: #C2CBD2;
$color-grey-600: #697885;
$color-gray-700: #536471;
$color-green-300: #21C473;
$color-yellow-400:#F6BF00;
$color-red-400: #E03220;
$color-brand-blue: #0096DF;
$color-border: #E8F0F3;
$border-radius: 0.9rem;

$color-primary-200: #DCDFFC;

// 900
@font-face {
  font-family: Nunito-Black;
  src: url(../assets/fonts/Nunito-Black.ttf);
}
@font-face {
  font-family: Nunito-BlackItalic;
  src: url(../assets/fonts/Nunito-BlackItalic.ttf);
}
// 800
@font-face {
  font-family: Nunito-ExtraBold;
  src: url(../assets/fonts/Nunito-ExtraBold.ttf);
}
@font-face {
  font-family: Nunito-ExtraBoldItalic;
  src: url(../assets/fonts/Nunito-ExtraBoldItalic.ttf);
}
// 700
@font-face {
  font-family: Nunito-Bold;
  src: url(../assets/fonts/Nunito-Bold.ttf);
}
@font-face {
  font-family: Nunito-BoldItalic;
  src: url(../assets/fonts/Nunito-BoldItalic.ttf);
}
// 600
@font-face {
  font-family: Nunito-SemiBold;
  src: url(../assets/fonts/Nunito-SemiBold.ttf);
}
@font-face {
  font-family: Nunito-SemiBoldItalic;
  src: url(../assets/fonts/Nunito-SemiBoldItalic.ttf);
}
// 500
@font-face {
  font-family: Nunito-Medium;
  src: url(../assets/fonts/Nunito-Medium.ttf);
}
@font-face {
  font-family: Nunito-MediumItalic;
  src: url(../assets/fonts/Nunito-MediumItalic.ttf);
}
// 400 
@font-face {
  font-family: Nunito-Regular;
  src: url(../assets/fonts/Nunito-Regular.ttf);
}
@font-face {
  font-family: Nunito-RegularItalic;
  src: url(../assets/fonts/Nunito-RegularItalic.ttf);
}
// 300
@font-face {
  font-family: Nunito-Light;
  src: url(../assets/fonts/Nunito-Light.ttf);
}
@font-face {
  font-family: Nunito-LightItalic;
  src: url(../assets/fonts/Nunito-LightItalic.ttf);
}
// 200
@font-face {
  font-family: Nunito-ExtraLight;
  src: url(../assets/fonts/Nunito-ExtraLight.ttf);
}
@font-face {
  font-family: Nunito-ExtraLightItalic;
  src: url(../assets/fonts/Nunito-ExtraLightItalic.ttf);
}

$font200: 'Nunito-ExtraLight';
$font200Italic: 'Nunito-ExtraLightItalic';
$font300: 'Nunito-Light';
$font300Italic: 'Nunito-LightItalic';
$font400: 'Nunito-Regular';
$font400Italic: 'Nunito-RegularItalic';
$font500: 'Nunito-Medium';
$font500Italic: 'Nunito-MediumItalic';
$font600: 'Nunito-SemiBold';
$font600Italic: 'Nunito-SemiBoldItalic';
$font700: 'Nunito-Bold';
$font700Italic: 'Nunito-BoldItalic';
$font800: 'Nunito-ExtraBold';
$font800Italic: 'Nunito-ExtraBoldItalic';
$font900: 'Nunito-Black';
$font900Italic: 'Nunito-BlackItalic';
