@import '../../../../style/variables';

.start-form-component {
  display: flex;
  flex-direction: column;

  .start-fields-container {
    margin-bottom: 3rem;
  }
  .start-button-container {
    display: flex;
    margin-bottom: 3rem;
    
    .start-button {
      display: flex;
      width:100%;
      border:none;
      background-color: $color-primary;
      padding: 16px 20px;
      margin-bottom: 1.9rem;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 1.5rem;
      font-family: $font700;
      color: $color-white;
      border-radius: 1000px;
    }
  }
}
