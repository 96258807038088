@import "../style/variables";

.status-page-component {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $color-white;
  pre {
    font-family: $font400;
    font-size: 1.4rem;
  }
}
