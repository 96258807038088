@import '../../../../style/variables';

.failure-route-component {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $color-white;

  p {
    font-family: $font400;
    font-size: 60px;
    line-height: 1.4;
  }
}
