@import '../../../../style/variables';

.address-form-component {
  display: flex;
  flex-direction: column;
  
  .MuiFormControl-root {
    flex:1;
  }
  
  .MuiFormControlLabel-label {
    font-family: $font400;
    font-size: 1.2rem;
  }
  .address-fields-container {
    margin-bottom: 3rem;
  }
  .address-row {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
  .state-control {
    width: 100%;
    padding: 20px 14px;
    border-radius: 12px;
    border-radius: 0.9rem;
    border: 1px solid #C2CBD2;
  }
  
  .invalid-state-feedback {
    color: #d32f2f;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
  }
  .address-button-container {
    display: flex;
    margin-bottom: 3rem;
    
    .address-button {
      display: flex;
      width:100%;
      border:none;
      background-color: $color-primary;
      padding: 16px 20px;
      margin-bottom: 1.9rem;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 1.5rem;
      font-family: $font700;
      color: $color-white;
      border-radius: 1000px;
    }
  }
}
