@import '../../../../style/variables';

.address-card-component {
  display: flex;
  flex-direction: column;
  width:100%;
  max-width: 400px;

  .back-container {
    display: flex;
    margin: 1rem 0 4rem;
  }

  .title-container {
    .title {
      color: $color-black;
      font-family: $font700;
      line-height: 3.3rem;
      font-size: 2.7rem;
      margin-bottom:0.66rem;
    }

    .subtitle {
      color: $color-black;
      font-family: $font400;
      font-weight:400;
      line-height: 2.34rem;
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }
  }
  .form-container {
    margin: 0;
  }
}
