@import '../../../../style/variables';

.information-form-component {
  display: flex;
  flex-direction: column;

  .MuiFormControlLabel-label {
    font-family: $font400;
    font-size: 1.2rem;
  }
  .information-fields-container {
    margin-bottom: 3rem;
  }
  .information-button-container {
    display: flex;
    margin-bottom: 3rem;
    
    .information-button {
      display: flex;
      width:100%;
      border:none;
      background-color: $color-primary;
      padding: 16px 20px;
      margin-bottom: 1.9rem;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 1.5rem;
      font-family: $font700;
      color: $color-white;
      border-radius: 1000px;
    }
  }
}
