@import '../../../../style/variables';

.confirm-send-card-component {
  display: flex;
  flex-direction: column;
  width:100%;
  max-width: 520px;
  padding:1rem;
  .back-container {
    display: flex;
    margin: 1rem 0 4rem;
  }

  .title-container {
    .title {
      color: $color-black;
      font-family: $font700;
      line-height: 3.3rem;
      font-size: 2.7rem;
      margin-bottom: 2.66rem;
    }
  }
  .confirm-wrapper {
    border-radius: 20px;
    background-color: $color-gray-150;
    margin-bottom: 3rem;
    padding: 2rem 0rem;

    .separator {
      background-color: $color-gray-200;
      height:1px;
      margin:2rem 0;
    }

    .confirm-lineitem {
      padding: 0 3rem;
    }
    
    .confirm-key {
      font-family: $font700;
      font-weight: 700;
      font-size: 1.3335rem;
      line-height: 1.4;
      margin-bottom:1rem;
    }
    .confirm-value {
      font-family: $font400Italic;
      font-weight: 400;
      font-size: 1.3335rem;
      line-height: 1.4;
    }
  }
  
  .confirm-send-button-container {
    display: flex;
    margin-bottom: 3rem;

    .confirm-send-button {
      display: flex;
      width:100%;
      border:none;
      background-color: $color-primary;
      padding: 16px 20px;
      margin-bottom: 1.9rem;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-family: $font700;
      font-size: 1.5rem;
      color: $color-white;
      border-radius: 1000px;
    }
  }
}
