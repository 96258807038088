@import '../../../../style/variables';

.astra-card-component {
  display: flex;
  flex-direction: column;
  width:100%;
  height:90%;

  .astra-frame {
    border:none;
    width:100%;
    height:100%;
  }
}
