@import '../../../../style/variables';
@import '../../../../style/breakpoints';

.layout-component {
  display: flex;
  flex: 1;
  flex-direction: row;
  position: relative;
  overflow: hidden;
  
  .sidebar-container {
    display: flex;
    flex-direction: column;
    width: 40%;
    
    img {
      position: absolute;
      z-index: -1;
      display: block;
    }

    .sidebar-bg-img {
      top: 50%;
      left:50%;
      transform: translate(-50%, -50%);
      width:100%;
      height:auto;
      object-fit: cover;
    }
    
    .sidebar-card-img {
      width:40%;
      top: 50%;
      transform: translate(0%, -50%);
    }

    @media all and (max-width: $tablet){
      display: none;
    }
    
  }

  .main-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: scroll;
  }

  .content-container {
    display: flex;
    flex: 1;
  }

  @media all and (max-width: $tablet){
    flex-direction: column;
  }
}
