.flex {
  display: flex;
}

.row {
  flex-direction: row;
}

.align-items-center {
  align-items: center;
}
