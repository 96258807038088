@import './style/form';
@import './style/utility';
@import "node_modules/normalize-scss/sass/normalize";
@include normalize();

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 12px;
  letter-spacing: 0.2px;
}

body {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  display: flex;
}

#root {
  display: flex;
  flex: 1;
}
