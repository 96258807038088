@import '../../../../style/variables';
@import '../../../../style/breakpoints';

.header-layout-component {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  header {
    z-index:1;
    box-shadow: 0 0px 24px 1px rgba(0, 0, 0, 0.05);
    padding: 1.5rem 0;
  }
  
  .main-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: scroll;
  }

  .content-container {
    display: flex;
    flex: 1;
  }

  @media all and (max-width: $tablet){
    flex-direction: column;
  }
}
