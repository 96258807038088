@import './variables';

.till-form {
  .MuiFormControl-root {
    margin-bottom: 1.6rem;
    width:100%;
  }

  .textfield {
    display: flex;
    flex: 1;
    
    fieldset {
      border-radius: $border-radius;
      border: 1px solid $color-gray-400;
    }

    label {
      padding-top:0.25rem;
      font-size: 1.2rem;
    }

    div {
      font-size: 1.2rem;
      min-height: 60px;
    }

    p.Mui-error {
      font-size: 1.2rem;
    }
  }
}
